export default {
    '推荐任务':"推薦任務",
    '查看更多':"查看更多",
    '人气排行':"人氣排行",
    '热门推荐':"熱門推薦",
    '播放':"播放",
    '获取中......':"獲取中......",
    '刷新成功':"刷新成功",
    '登录':"登錄",
    '请输入用户名':"請輸入用戶名",
    '忘记密码':"忘記密碼",
    '没有账户？马上注册':"沒有賬戶？馬上註冊",
    '请输入密码':"請輸入密碼",
    '注册':"註冊",
    '请输入邀请码':"請輸入邀請碼",
    '请输入用户名(6-12个英文字母或数字)':"請輸入用戶名(6-12個英文字母或數字)",
    '请输入登录密码(6-12个英文字母或数字)':"請輸入登錄密碼(6-12個英文字母或數字)",
    '我已经知晓并同意"开户协议"各项条约':"我已經知曉並同意\"開戶協議\"各項條約",
    '请勾选下方开户协议':"請勾選下方開戶協議",
    '玩法规则':"玩法規則",
    '玩法提示':"玩法提示",
    '任务记录':"任務記錄",
    '从可选和值形态里面选择号码进行下注':"從可選和值形態裏面選擇號碼進行下註",
    '中奖说明':"中獎說明",
    '三个开奖号码总和值11~18 为大;总和值3~ 10为小':"三個開獎號碼總和值11~18 為大;總和值3~ 10為小",
    '投注范例':"投註範例",
    '投注方案：小 开奖号码：123,即中小':"投註方案：小 開獎號碼：123,即中小",
    '任务单':"任務單",
    '可用余额':"可用余額",
    '元':"元",
    '提交':"提交",
    '当前选号':"當前選號",
    '每注金额':"每註金額",
    '请输入金额':"請輸入金額",
    '总共':"總共",
    '注':"註",
    '合计':"合計",
    '清空订单':"清空訂單",
    '确认提交':"確認提交",
    '期号':"期號",
    '开奖号码':"開獎號碼",
    '大':"大",
    '小':"小",
    '单':"單",
    '双':"雙",
    '金额错误':"金額錯誤",
    '请选号':"請選號",
    '请填写金额':"請填寫金額",
    '余额不足，请联系客服充值':"余額不足，請聯系客服充值",
    '未选中':"未選中",
    '请联系管理员领取该任务':"請聯系管理員領取該任務",
    '开奖成功，期号':"開獎成功，期號",
    '填写收款卡':"填寫收款卡",
    '请输入您的收款卡信息':"請輸入您的收款卡信息",
    '银行卡号':"銀行卡號",
    '请输入真实银行卡号':"請輸入真實銀行卡號",
    '银行名称':"銀行名稱",
    '请选择银行':"請選擇銀行",
    '尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款':"尊敬的用戶,為了保障您的資金安全,請您綁定您的真實姓名和設置取款密碼,如果姓名與開戶名不一致,將無法取款",
    '确认绑卡':"確認綁卡",
    '请输入银行卡号':"請輸入銀行卡號",
    '数据为空':"數據為空",
    '下注金额':"下註金額",
    '金额':"金額",
    '任务类型':"任務類型",
    '下单时间':"下單時間",
    '结算时间':"結算時間",
    '充值':"充值",
    '提现':"提現",
    '我的钱包':"我的錢包",
    '详情':"詳情",
    '余额(元)':"余額(元)",
    '个人报表':"個人報表",
    '账户明细':"賬戶明細",
    '个人中心':"個人中心",
    '信息公告':"信息公告",
    '在线客服':"在線客服",
    '请完成任务单后进入':"請完成任務單後進入",
    '请联系客服充值':"請聯系客服充值",
    '功能已禁用':"功能已禁用",
    '账号下线':"賬號下線",
    '登录/注册':"登錄/註冊",
    '登录可享受更多服务':"登錄可享受更多服務",
    '头像':"頭像",
    '选择头像':"選擇頭像",
    '确定':"確定",
    '真实姓名':"真實姓名",
    '性别':"性別",
    '绑定信息':"綁定信息",
    '已绑定':"已綁定",
    '无':"無",
    '男':"男",
    '女':"女",
    '未知':"未知",
    '盈利金额(元)':"盈利金額(元)",
    '盈利计算公式 : 中奖金额 - 任务金额':"盈利計算公式 : 中獎金額 - 任務金額",
    '任务金额':"任務金額",
    '充值金额':"充值金額",
    '提现金额':"提現金額",
    '中奖金额':"中獎金額",
    '联系':"聯系",
    '全天7 * 24小时竭诚为您服务':"全天7 * 24小時竭誠為您服務",
    '收款卡信息':"收款卡信息",
    '添加收款卡':"添加收款卡",
    '提示:请绑定大型商业银行,如需修改,请您联系在线客服':"提示:請綁定大型商業銀行,如需修改,請您聯系在線客服",
    '请设置姓名后再绑定银行卡':"請設置姓名後再綁定銀行卡",
    '请设置提现密码后再绑定银行卡':"請設置提現密碼後再綁定銀行卡",
    '修改登录密码':"修改登錄密碼",
    '保存':"保存",
    '旧密码':"舊密碼",
    '请输入您的旧密码':"請輸入您的舊密碼",
    '新密码':"新密碼",
    '请输入您的新密码':"請輸入您的新密碼",
    '请填写完整':"請填寫完整",
    '两次密码输入不一致':"兩次密碼輸入不一致",
    '修改真实姓名':"修改真實姓名",
    '请输入真实姓名':"請輸入真實姓名",
    '为了您账户安全,真实姓名需要与绑定银行卡姓名一致':"為了您賬戶安全,真實姓名需要與綁定銀行卡姓名一致",
    '设置资金密码':"設置資金密碼",
    '请输入资金密码':"請輸入資金密碼",
    '请再次输入资金密码':"請再次輸入資金密碼",
    '性别修改':"性別修改",
    '设置':"設置",
    '基本信息设置':"基本信息設置",
    '登录密码':"登錄密碼",
    '资金密码':"資金密碼",
    '退出登录':"退出登錄",
    '提现密码已设置，需要修改请联系客服':"提現密碼已設置，需要修改請聯系客服",
    '已设置':"已設置",
    '未设置':"未設置",
    '提现中心':"提現中心",
    '提现金额 (元)':"提現金額 (元)",
    '全部':"全部",
    '到账时间：一般到账时间在5分钟左右，最快2分钟内到账':"到賬時間：一般到賬時間在5分鐘左右，最快2分鐘內到賬",
    '限额说明':"限額說明",
    '余额':"余額",
    '马上提现':"馬上提現",
    '请填写正确的金额':"請填寫正確的金額",
    '提现记录':"提現記錄",
    '说明':"說明",
    '提交时间':"提交時間",
    '审核时间':"審核時間",
    '视频影院':"視頻影院",
    '没有更多了':"沒有更多了",
    '次播放':"次播放",
    '请充值后观看视频':"請充值後觀看視頻",
    '预约大厅':"預約大廳",
    '首页':"首页",
    '预约':"预约",
    '视频':"视频",
    '我的':"我的",
    '空降':"空降",
    '约炮':"約炮",
    '同城':"同城",
    '空降活动':"空降活動",
    '约炮活动':"約炮活動",
    '同城活动':"同城活動",
    '制服':"製服",
    '国产':"國產",
    '最新':"最新",
    '最热':"最熱",
    '推荐':"推薦",
    '亚洲':"亞洲",
    '直播':"直播",
    '偷拍':"偷拍",
    '欧美':"歐美",
    '动漫':"動漫",
    '测试':"測試",
    '姓名':"姓名",
    '基本信息':"基本信息",
    '系统公告':"系統公告",
    '提现金额(元)':'提現金額(元)'
}
