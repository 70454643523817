import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
import {i18n, vantLocales} from "./lang/index"
import 'video.js/dist/video-js.css'
Vue.prototype.$t = (key, value) => i18n.t(key, value)
Vue.use(vantLocales);
vantLocales(i18n.locale);
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
