export default {
 '推荐任务':"Recommend tasks",
 '查看更多':"View more",
 '人气排行':"Popularity Ranking",
 '热门推荐':"Hot Picks",
 '播放':"Play",
 '获取中......':"Get it......",
 '刷新成功':"Refresh successfully",
 '登录':"Login",
 '请输入用户名':"Please enter user name",
 '忘记密码':"Forgot Password",
 '没有账户？马上注册':"Don’t have an account?Register Now",
 '请输入密码':"Please enter password",
 '注册':"Register",
 '请输入邀请码':"Please enter a invitation code",
 '请输入用户名(6-12个英文字母或数字)':"Please enter your username (6-12 letters or numbers)",
 '请输入登录密码(6-12个英文字母或数字)':"Please enter your login password (6-12 letters or numbers)",
 '我已经知晓并同意"开户协议"各项条约':"I have understood and agreed of the \"Account Opening Agreement\"others terms & condition",
 '请勾选下方开户协议':"Please check the account opening agreement below",
 '玩法规则':"Rules",
 '玩法提示':"Playing Tips",
 '任务记录':"Task’s Record",
 '从可选和值形态里面选择号码进行下注':"Select numbers from the selectable sum patterns to place a bet",
 '中奖说明':"Winning description",
 '三个开奖号码总和值11~18 为大;总和值3~ 10为小':"The total sum of the three lottery winning numbers is between 11~18 for large; 3~10 for small",
 '投注范例':"Example of bets",
 '投注方案：小 开奖号码：123,即中小':"Betting scheme：123,as small the winning lottery number ",
 '任务单':"Tasks",
 '可用余额':"Balance",
 '元':"CNY",
 '提交':"Submit",
 '当前选号':"Current number selection",
 '每注金额':"Amount per bet",
 '请输入金额':"Please enter an amount",
 '总共':"Total",
 '注':"Bet",
 '合计':"Total",
 '清空订单':"Clear orders",
 '确认提交':"Confirm Submission",
 '期号':"Period Number",
 '开奖号码':"Lottery number",
 '大':"Big",
 '小':"Small",
 '单':"Odd",
 '双':"Even",
 '金额错误':"Amount error",
 '请选号':"Please select a number",
 '请填写金额':"Please fill in the amount",
 '余额不足，请联系客服充值':"If the balance is insufficient, please contact customer service to top up",
 '未选中':"Not selected",
 '请联系管理员领取该任务':"Please contact the administrator for this task",
 '开奖成功，期号':"Lottery success, period number",
 '填写收款卡':"Fill out the receipt card",
 '请输入您的收款卡信息':"Please enter your payment card information",
 '银行卡号':"Bank Card No.",
 '请输入真实银行卡号':"Please enter your real bank card no.",
 '银行名称':"Bank",
 '请选择银行':"Please select a bank",
 '尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款':"Dear users, in order to protect the safety of your funds, please bind your real name and set the withdrawal password, if your name and account name are not the same, you will not be able to withdraw funds",
 '确认绑卡':"Confirm card subscription",
 '请输入银行卡号':"Please enter your bank card number",
 '数据为空':"Empty Data",
 '下注金额':"Bet amount",
 '金额':"Amount",
 '任务类型':"Type of task",
 '下单时间':"Order time",
 '结算时间':"Settlement time",
 '充值':"Top up",
 '提现':"Withdrawal",
 '我的钱包':"My wallet",
 '详情':"Details",
 '余额(元)':"Balance (yuan)",
 '个人报表':"Personal Statement",
 '账户明细':"Account Details",
 '个人中心':"Personal Center",
 '信息公告':"Announcement",
 '在线客服':"Online Customer Service",
 '请完成任务单后进入':"Please complete the task order and enter",
 '请联系客服充值':"Please contact customer service for top-up",
 '功能已禁用':"Function disabled",
 '账号下线':"Account offline",
 '登录/注册':"Login/Register",
 '登录可享受更多服务':"Login to enjoy more services",
 '头像':"Avatar",
 '选择头像':"Select Avatar",
 '确定':"Confirm",
 '真实姓名':"Real name",
 '性别':"Gender",
 '绑定信息':"Binding Information",
 '已绑定':"Bound",
 '无':"None",
 '男':"Male",
 '女':"Female",
 '未知':"Unknown",
 '盈利金额(元)':"Profit amount (yuan)",
 '盈利计算公式 : 中奖金额 - 任务金额':"Profit Calculation Formula : Winning Amount - Task Amount",
 '任务金额':"Task amount",
 '充值金额':"Top up amount",
 '提现金额':"withdrawal amount",
 '中奖金额':"Winning amount",
 '请输入资金密码':"Please enter your fund password",
 '请再次输入资金密码':"Please enter your fund password again",
 '性别修改':"Gender Modification",
 '设置':"Setting",
 '基本信息设置':"Basic Information Settings",
 '登录密码':"Login Password",
 '资金密码':"Fund Password",
 '退出登录':"Logout",
 '提现密码已设置，需要修改请联系客服':"Withdrawal password has been set, please contact customer service if you need to change it",
 '已设置':"Set up",
 '未设置':"Not set",
 '提现中心':"Withdrawal",
 '提现金额 (元)':"Cash withdrawal amount (yuan)",
 '全部':"All",
 '到账时间：一般到账时间在5分钟左右，最快2分钟内到账':"receiving time: The general receiving time is around 5 minutes, the fastest within 2 minutes",
 '限额说明':"Amount Limit Description",
 '余额':"Balance",
 '马上提现':"Withdrawal now",
 '请填写正确的金额':"Please fill in the correct amount",
 '提现记录':"records",
 '说明':"Description",
 '提交时间':"Submission time",
 '审核时间':"Audit time",
 '视频影院':"Video Theater",
 '没有更多了':"No more",
 '次播放':"Play",
 '请充值后观看视频':"Please top up and watch the video",
 '预约大厅':"Reservation Hall",
 '首页':"Home",
 '预约':"reserve",
 '视频':"video",
 '我的':"Mine",
 '空降':"Airborne",
 '约炮':"dating",
 '同城':"In Town",
 '空降活动':"AirborneActivities",
 '约炮活动':"Dating activities",
 '同城活动':"In town activities",
 '制服':"uniform",
 '国产':"domestic",
 '最新':"News",
 '最热':"Hot",
 '推荐':"recommend",
 '亚洲':"Asia",
 '直播':"Live",
 '偷拍':"Candid",
 '欧美':"Western",
 '动漫':"Cartoon",
 '测试':"Test",
 '旧密码':"old password",
 '请输入您的旧密码':"your old password",
 '新密码':"new password",
 '请输入您的新密码':"your new password",
 '请填写完整':"Please fill in completely",
 '两次密码输入不一致':"Inconsistent password entered twice",
 '修改真实姓名':"Edit real name",
 '请输入真实姓名':"your real name",
 '为了您账户安全,真实姓名需要与绑定银行卡姓名一致':"For the security of your account, the real name needs to be the same as the name of the bound bank card",
 '设置资金密码':"Set up a fund password",
 '保存':'save',
 '修改登录密码':'Modify login password',
 '姓名':"name",
 '基本信息':"Information",
 '系统公告':"notification",
 '提现金额(元)':'Withdrawal amount (cny)'
}
